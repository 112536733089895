<template lang="pug">
  v-app.split-bg.custom-font
    a.whatsappFloat(href='https://api.whatsapp.com/send/?phone=593988511884&text&app_absent=0' target='_blank')
      v-icon.my-float(large style='color: #ffffff;') mdi-whatsapp
    a.icon-bar-facebook.facebook(href='https://www.facebook.com/j2rtech' target='_blank')
      v-icon.my-float(large style='color: #ffffff;') mdi-facebook
    a.icon-bar-instagram.instagram(href='https://www.instagram.com/j2rtech/' target='_blank')
      v-icon.my-float(large style='color: #ffffff') mdi-instagram
    nav.navbar.navbar-expand-lg.navbar-dark.fixed-top
      //-   <a class="navbar-brand" href="#">Navbar</a>
      //-   <a href="#intro" class="navbar-brand smoothScroll">
      //-   <img class="complete-logo" src="img/logo-simbolo-2.png" width="300" height="60" alt="Logo" title="Inventarix">
      //-   </a>
      a.navbar-brand.smoothScroll(href='#banner')
        img.header-logo(src='@/assets/img-landing/simbolo-solo.png' alt='Logo de Inventarix' title='Inventarix')
      button.navbar-toggler(type='button' data-toggle='collapse' data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation')
        span.navbar-toggler-icon
      #navbarSupportedContent.collapse.navbar-collapse.collapsed-controller
        ul.navbar-nav.mr-auto
          li.nav-item.active
            a.nav-link(href='#banner') Inicio
          li.nav-item.active
            a.nav-link(href='#what-use-in') &iquest;Para que sirve?
          li.nav-item.active
            a.nav-link(href='#look-how-works') &iquest;C&oacute;mo se usa?
          li.nav-item.active
            a.nav-link(href='#price-table') &iquest;Precios?
          li.nav-item.active
            a.nav-link(href='#faq-section') Más
          li.nav-item.active
            a.nav-link.phone-number(href='https://api.whatsapp.com/send/?phone=%2B593984530998&text&app_absent=0' target='_blank')
              | (+593)
              | 98-453-0998
        a.btn.my-4.font-weight-bold.atlas-cta.cta-green(@click='accessToApp()' data-aos='fade' data-aos-easing='linear' data-aos-duration='1000' data-aos-once='true' style='font-size:15px;')
          | Accede
    //- .pos-f-t
    //-   #navbarToggleExternalContent.collapse
    //-     .bg-dark.p-4
    //-       h4.text-white Collapsed content
    //-       span.text-muted Toggleable via the navbar brand.
    //- // banner
    #banner.jumbotron.jumbotron-fluid.banner-bg-img
      .container.text-center.text-md-left
        v-row
          v-col(cols='12' md="6" style="padding-top:50px;")
            //- style="font-family: 'ceraProBlack', sans-serif !important; font-size: 40px;"
            h1.display-3.font-weight-bold.my-5(data-aos='fade' data-aos-easing='linear' data-aos-duration='1000' data-aos-once='true' style="font-family: 'ceraProBlack', sans-serif !important;")
              | INVENTARIX
            h2 Inventario y Facturaci&oacute;n Electr&oacute;nica
            p.my-4(data-aos='fade' data-aos-easing='linear' data-aos-duration='1000' data-aos-once='true')
              | Reg&iacute;stra tu inventario, clientes, factura electr&oacute;nicamente y m&aacute;s desde cualquier dispositivo
          v-col(cols='12' md='5').front-icon(data-aos='fade-up' data-aos-duration='1000' data-aos-once='true')
            img(src='@/assets/img-landing/front-image2.png' alt='Preview de Inventarix' style='margin-top:80px;')
        v-row
          v-col.center-button(cols='12' lg="6" style="margin: 10px 0")
            a.btn.my-4.font-weight-bold.atlas-cta.cta-green(@click='accessToApp()' data-aos='fade' data-aos-easing='linear' data-aos-duration='1000' data-aos-once='true' style='font-size:20px;')
              | Reg&iacute;strate gratis
    //- // three-block
    #what-use-in.container.my-5.py-2(style="margin: 48px auto;")
      h2.text-center.font-weight-bold.my-5(style="margin:48px 0 !important") Automatiza los procesos en tu negocio
      .row
        .col-md-4.text-center(data-aos='fade-up' data-aos-delay='0' data-aos-duration='1000' data-aos-once='true')
          img.mx-auto(src='@/assets/img-landing/clients-provider.png' alt='proveedores y clientes')
          h4 Clientes y Proveedores
          p
            | Reg&iacute;stra los datos de tus clientes y proveedores, adem&aacute;s cons&uacute;ltalos desde cualquier dispositivo
        .col-md-4.text-center(data-aos='fade-up' data-aos-delay='200' data-aos-duration='1000' data-aos-once='true')
          img.mx-auto(src='@/assets/img-landing/statistics.png' alt='Estadísticas')
          h4 Reportes y estad&iacute;sticas
          p
            | Reportes generados autom&aacute;ticamente para que se puedan consultar o imprimir y agilizar la toma de desiciones
        .col-md-4.text-center(data-aos='fade-up' data-aos-delay='400' data-aos-duration='1000' data-aos-once='true')
          img.mx-auto(src='@/assets/img-landing/bill-eletronic.png' alt='Estadísticas')
          h4 Facturaci&oacute;n electr&oacute;nica
          p
            | Factura electr&oacute;nicamente con pocos clics y env&iacute;a las facturas a los correos de tus clientes
    //- // feature
    #feature-first.jumbotron.jumbotron-fluid.feature
      .container.my-5
        .row.justify-content-between.text-center.text-md-left
          .col-md-6(data-aos='fade-up' data-aos-duration='1000' data-aos-once='true')
            h2.font-weight-bold Hecha un vistazo
            p.my-4(style="24px 0 !important")
              | Empieza a facturar desde hoy mismo y prueba todas funcionalidades
              br
              | Inventarix cuenta con m&oacute;dulo especializado para restaurantes y se vienen muchas cosas m&aacute;s &#x1F92B;
            a#look-how-works.btn.my-4.font-weight-bold.atlas-cta.cta-blue(@click='accessToApp()' style="color: white;")
              | Reg&iacute;strate gratis
          .col-md-6.align-self-center(data-aos='fade-down' data-aos-duration='1000' data-aos-once='true')
            img.mx-auto.d-block(src='@/assets/img-landing/feature-1.png' alt='Hecha un vistazo')
    //- // feature (green background)
    #feature-last.jumbotron.jumbotron-fluid.feature
      .container
        .row.justify-content-between.text-center.text-md-left
          .col-md-6.flex-md-last(data-aos='fade-up' data-aos-duration='1010' data-aos-once='true')
            h2.font-weight-bold Mira como funciona
            p.my-4
              | Mira los tutoriales de como funciona Inventarix
              br
              |             Adem&aacute;s contamos con soporte presencial y remoto
            a.btn.my-4.font-weight-bold.atlas-cta.cta-blue(href='https://www.youtube.com/watch?v=3UD5fZ-fsvU&t=2s' target="_blank" style="color: white;") Videos
          .col-md-6.align-self-center.flex-md-first(data-aos='fade-down' data-aos-duration='1000' data-aos-once='true')
            img.mx-auto.d-block(src='@/assets/img-landing/feature-2.png' alt='Mira como funcion')
    //- // price table
    #price-table.container.my-5.py-2
      h2.text-center.font-weight-bold.d-block.mb-3 Un plan a tu medida
      v-row
        #price-table__monthly.col-md-4.text-center.py-4.mt-5(data-aos='fade-down' data-aos-delay='200' data-aos-duration='1000' data-aos-once='true')
          h4.my-4 MENSUAL
          p.font-weight-bold
            | $
            span.display-2.font-weight-bold.prices(style="font-family: 'CeraProRegular', sans-serif !important; font-size: 88px !important;")
              | 8.80
            | / Mes
          p(style='font-weight:bold;') Ahorro total $0.00
          ul.list-unstyled
            li Base de datos en tiempo real
            li Respaldos diarios
            li Soporte limitado
          a.btn.my-4.font-weight-bold.atlas-cta.cta-ghost(@click='accessToApp()' style="color:#192440;") Prueba gratis
        #price-table__annual.col-md-4.text-center.py-4.mt-5.rounded(data-aos='fade-up' data-aos-duration='1000' data-aos-once='true')
          h4.my-4 ANUAL
          p.font-weight-bold
            | $
            span.display-2.font-weight-bold.prices(style="font-family: 'CeraProRegular', sans-serif !important; font-size: 88px !important;")
              | 57.50
            | / a&ntilde;o
          p(style='font-weight:bold;')  Ahorro total $48.10
          ul.list-unstyled
            li Base de datos en tiempo real
            li Respaldos diarios
            li Soporte limitado
            li Primeros en probar nuevas carácteristicas y mejoras
            li Soporte técnico 24/7
          a#faq-section.btn.my-4.font-weight-bold.atlas-cta.cta-green(@click='accessToApp()') Prueba gratis
        #price-table__semester.col-md-4.text-center.py-4.mt-5(data-aos='fade-down' data-aos-delay='200' data-aos-duration='1000' data-aos-once='true')
          h4.my-4 SEMESTRAL
          p.font-weight-bold
            | $
            span.display-2.font-weight-bold.prices(style="font-family: 'CeraProRegular', sans-serif !important; font-size: 88px !important;")
              | 34.50
            | / semestre
          p(style='font-weight:bold;')  Ahorro total $18.30
          ul.list-unstyled
            li Base de datos en tiempo real
            li Respaldos diarios
            li Soporte limitado
            li Escalamiento automático
          a.btn.my-4.font-weight-bold.atlas-cta.cta-ghost(@click='accessToApp()' style="color:#192440;") Prueba gratis
    //- faq section
    .container.my-5.py-2
      h2.text-center.font-weight-bold.d-block.mb-3 Tenemos respuestas 😉
      v-row(style='display:flex; justify-content:center; align-items:center;')
        v-col(cols='12' sm='8')
          v-expansion-panels
            v-expansion-panel
              v-expansion-panel-header
                | ¿Qué equipo o dispositivo necesito para ingresar a la app?
                template(v-slot:actions='')
                  v-icon(style='color: #192440')
                    | mdi-arrow-down-drop-circle
              v-expansion-panel-content
                | Puedes usar cualquier equipo o dispositivo móvil que tenga conexión a internet, Inventarix se adapta a cualquier dispositivo y tamaño de pantalla.
            v-expansion-panel
              v-expansion-panel-header(disable-icon-rotate='')
                | ¿Necesito instalar alguna aplicación desde la tienda virtual o desde la web?
                template(v-slot:actions='')
                  v-icon(style='color: #192440')
                    | mdi-arrow-down-drop-circle
              v-expansion-panel-content
                | No necesitas instalar nada, solo debes acceder a la página https://inventarix.com desde tu navegador.
            v-expansion-panel
              v-expansion-panel-header(disable-icon-rotate='')
                | Si olvido mi contraseña ¿Cómo podría recuperarla?
                template(v-slot:actions='')
                  v-icon(style='color: #192440')
                    | mdi-arrow-down-drop-circle
              v-expansion-panel-content
                | Claro que sí, solo debe presionar el botón “Olvide mi Contraseña”, después ingresar tu correo y te enviaremos toda la información que necesitas y así poder ingresar a tu plataforma Inventarix.
            v-expansion-panel
              v-expansion-panel-header(disable-icon-rotate='')
                | Si cancelo mi suscripción, ¿Pierdo todos los datos?
                template(v-slot:actions='')
                  v-icon(style='color: #192440')
                    | mdi-arrow-down-drop-circle
              v-expansion-panel-content
                | Todos tus datos permanecerán en nuestros servidores y no se perderán, cuando vuelvas a Inventarix encontrarás todo como lo dejaste.
            v-expansion-panel
              v-expansion-panel-header(disable-icon-rotate='')
                | ¿Si uso otro sistema como podría migrar a Inventarix?
                template(v-slot:actions='')
                  v-icon(style='color: #192440')
                    | mdi-arrow-down-drop-circle
              v-expansion-panel-content
                | Inventarix permite importar datos desde archivos de formato Excel para agilizar el proceso de migración de datos.
            v-expansion-panel
              v-expansion-panel-header(disable-icon-rotate='')
                | ¿Si algún momento quiero dejar de usar Inventarix como puedo obtener mis datos?
                template(v-slot:actions='')
                  v-icon(style='color: #192440')
                    | mdi-arrow-down-drop-circle
              v-expansion-panel-content
                | Inventarix permite descargar en formato Excel todos los datos de sus usuarios en caso de que requiera hacer migraciones, integraciones, etc.
            v-expansion-panel
              v-expansion-panel-header(disable-icon-rotate='')
                | ¿Qué tipo de firma electrónica es recomendable para facturar electrónicamente?
                template(v-slot:actions='')
                  v-icon(style='color: #192440')
                    | mdi-arrow-down-drop-circle
              v-expansion-panel-content
                | Para facturar electrónicamente es necesario adquirir previamente una firma electrónica en archivo formato PFX-P12.
    .jumbotron.jumbotron-fluid
      .container
        .row.partners-logos
          .col-sm-4.col-md-2.py-2.align-self-center
            a(target='_blank' href='https://www.facebook.com/donamenestra')
              img.mx-auto.d-block(src='@/assets/img-landing/menestra-logo2.png' alt='Logo Doña Menestra')
          .col-sm-4.col-md-2.py-2.align-self-center
            a(target='_blank' href='https://j2rtech.com/')
              img.mx-auto.d-block(src='@/assets/img-landing/j2rtech-logo.png' alt='Logo J2R TECH')
          .col-sm-4.col-md-2.py-2.align-self-center
            a(target='_blank' href='https://www.facebook.com/AMSA-de-Comercio-y-Consultores-263516884404005/')
              img.mx-auto.d-block(src='@/assets/img-landing/logo-amsa.png' alt='Logo AMSA')
          .col-sm-4.col-md-2.py-2.align-self-center
            a(target='_blank' href='https://www.facebook.com/DistribuidoraJSCosmeticos/')
              img.mx-auto.d-block(src='@/assets/img-landing/logo-distr-belleza.png' alt='Logo de empresa de distribuidores')
          .col-sm-4.col-md-2.py-2.align-self-center
            a(target='_blank' href='https://www.eoncorp.ec/')
              img.mx-auto.d-block(src='@/assets/img-landing/logo-eon.png' alt='Logo EON CORP')
    //-       // logos de los demas clientes
    //-         <div class="col-sm-4 col-md-2 py-2 align-self-center">
    //-         <img src="img/client-2.png" class="mx-auto d-block">
    //-         </div>
    //-         <div class="col-sm-4 col-md-2 py-2 align-self-center">
    //-         <img src="img/client-3.png" class="mx-auto d-block">
    //-         </div>
    //-         <div class="col-sm-4 col-md-2 py-2 align-self-center">
    //-         <img src="img/client-4.png" class="mx-auto d-block">
    //-         </div>
    //-         <div class="col-sm-4 col-md-2 py-2 align-self-center">
    //-         <img src="img/client-5.png" class="mx-auto d-block">
    //-         </div>
    //-         <div class="col-sm-4 col-md-2 py-2 align-self-center">
    //-         <img src="img/client-6.png" class="mx-auto d-block">
    //-         </div>
    //- // contact
    #contact.jumbotron.jumbotron-fluid.contact-bg-img
      .container.my-5
        .row.justify-content-between
          .col-md-6.text-white
            h2.font-weight-bold Cont&aacute;ctanos
            p.my-4
              | Cuenca
              br
              | Yanahurco 4-91 y Rio Upano
              br
              | Tel: 0988511884
            p.my-4
              | Machala
              br
              | Tel: 0984530998
            p.my-4
              | Quito
              br
              | Tel: 0990793760
            //- //
            //-   <ul class="list-unstyled">
            //-   <li>Email : company_email@com</li>
            //-   <li>Phone : 361-688-5824</li>
            //-   <li>Address : 4826 White Avenue, Corpus Christi, Texas</li>
            //-   </ul>
          .col-md-6
            form
              .row
                .form-group.col-md-6
                  label(for='name') Tu nombre
                  input#name.form-control(type='name' v-model='contactInfo.contactName')
                .form-group.col-md-6
                  label(for='Email') Tu correo
                  input#Email.form-control(type='email' v-model='contactInfo.contactEmail')
              .form-group
                label(for='message') Mensaje
                textarea#message.form-control(rows='3' v-model='contactInfo.contactMessage')
              button.btn.font-weight-bold.atlas-cta.atlas-cta-wide.cta-green.my-3(type='submit' @click='sendInfoToContact()')
                | Enviar
    //- // copyright
    #copyright.jumbotron.jumbotron-fluid
      .container
        .row.justify-content-between
          .text-white.align-self-center.text-center.text-md-center.copyright-style
            | Copyright &copy; 2022 Inventarix. Desarrollado por &nbsp
            a.productBy(href='https://j2rtech.com/' target='_blank') J2R TECH S.A.S.
          //- // links a redes sociales
          //- //
          //-   <div class="col-md-6 align-self-center text-center text-md-right my-2" id="social-media">
          //-   <a href="#" class="d-inline-block text-center ml-2">
          //-   <i class="fa fa-facebook" aria-hidden="true"></i>
          //-   </a>
          //-   <a href="#" class="d-inline-block text-center ml-2">
          //-   <i class="fa fa-twitter" aria-hidden="true"></i>
          //-   </a>
          //-   <a href="#" class="d-inline-block text-center ml-2">
          //-   <i class="fa fa-medium" aria-hidden="true"></i>
          //-   </a>
          //-   <a href="#" class="d-inline-block text-center ml-2">
          //-   <i class="fa fa-linkedin" aria-hidden="true"></i>
          //-   </a>
          //-   </div>
    //- // AOS
    //- script(src='js/aos.js')
    //- script.
    //-   AOS.init({});
</template>

<script>
  // import AosVue from 'aos-vue'
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/database'
  // import moment from 'moment'
  // var functions = require('../../functions/functions')

  export default {
    // components: { AosVue },
    data: () => ({
      contactInfo: {
        contactName: '',
        contactEmail: '',
        contactMessage: '',
      },
    }),
    methods: {
      accessToApp () {
        this.$router.push('/login')
      },
      sendInfoToContact () {
        firebase.database().ref('landingContact/').push(this.contactInfo).then((res) => {
          alert('Sus datos fueron recibidos por el equipo de Inventarix, en breve nos comunicaremos con usted')
        }).catch((err) => {
          alert(err)
        })
      },
    },
  }
</script>

<style lang="scss">
/* breakpoints for bootstrap
Bootstrap: 576px, 768px, 992px, and 1200px */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
/* html{
  font-size: 62.5%;
}
body {
  font-size: 1.6rem;
} */
@font-face {
  font-family: "ceraProBlack";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Black.otf');
  font-display: swap;
}
@font-face {
  font-family: "ceraProRegular";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Regular.otf');
  font-display: swap;
}
@font-face {
  font-family: "ceraProMedium";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Medium.otf');
  font-display: swap;
}
@font-face {
  font-family: "ceraProBold";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Bold.otf');
  font-display: swap;
}
body {
  font-family: 'ceraProBlack', sans-serif !important;
}
h1 {
  font-family: 'ceraProBlack', sans-serif !important;
  color: #00ffad !important;
}
h4 {
  font-family: 'ceraProBlack', sans-serif !important;
  font-size: 24px;
}
h2 {
  font-family: 'ceraProBlack', sans-serif !important;
  font-size: 32px;
}
#banner h2 {
  font-size: 41.6px;
}
a,
button {
  font-family: 'ceraProBold', sans-serif !important;
}
p {
  font-family: 'ceraProRegular', sans-serif !important;
}
ul {
  font-family: 'ceraProRegular', sans-serif !important;
}
label {
  font-family: 'ceraProRegular', sans-serif !important;
}
p span{
  font-size: 88px;
}
img {
  max-width: 100%;
  height: auto;
}
#banner h2 {
  // font-size: 2.6rem !important;
  // font-weight: 700;
  color: rgb(231, 231, 231);
}
#banner p {
  font-size: 20px;
  color: rgb(165, 162, 162);
}
#what-use-in p {
  color: black;
}
img {
  display: block;
  max-width: 100%;
}

a,
button {
  cursor: pointer;
}

nav {
  background-color: #1b1b33;
}

/* para el boton flotante */
.v-application a{
  text-decoration: none;
}
.productBy{
  color: #00ffad !important;
}
.whatsappFloat {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-content: center;
}

.my-float {
  margin: 0;
}

.icon-bar-facebook {
  position: fixed;
  top: 90%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99999;
}

.icon-bar-facebook a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar-facebook a:hover {
  background-color: #000;
}

.icon-bar-instagram {
  position: fixed;
  top: 84.5%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99999;
}

.icon-bar-instagram a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar-instagram a:hover {
  background-color: #000;
}

.facebook {
  background: #3B5998;
  color: white;
}

.instagram {
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  color: white;
}

.prices {
  font-size:88px !important;
}
.collapsed-controller {
  text-align: center;
}
.custom-font{
  font-family: 'ceraProRegular', sans-serif !important;
}
.banner-bg-img{
  background-image: url("~@/assets/img-landing/banner-bk2.png");
}
.contact-bg-img{
  background-image: url("~@/assets/img-landing/contact-bk.jpg");
}
.header-logo{
  width: 60px;
}
.mr-auto {
  margin: 0 auto;
}
.nav-link{
  color: white !important;
  font-size: 19px;
}
.nav-item a:hover{
  color: #45ffad !important;
}
.phone-number {
  color: #00ffad !important;
}
.front-icon {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.copyright-style {
  margin: 0 auto;
}

.partners-logos {
  display: flex;
  justify-content: center;
}

.jumbotron {
  margin-bottom: 0 !important;
}

.atlas-cta {
  border-radius: 22px !important;
  padding: 12px 30px !important;
  font-weight: 700;
  transition: 0.3s ease-in-out !important;
}

.atlas-cta:hover {
  text-decoration: none;
  transform: translateY(-5px);
}

.atlas-cta-wide {
  width: 100%;
}

.cta-green {
  background: #00ffad;
  color: #192440 !important;
}

.cta-green:hover {
  color: #ffffff !important;
  background: #00e69c;
}

.cta-blue {
  background: #192440;
  color: #ffffff;
}

.cta-blue:hover {
  color: #ffffff !important;
  background: #121a2e;
}

.cta-ghost {
  border: 2px solid #192440 !important;
  color: #192440;
}

.cta-ghost:hover {
  color: #ffffff !important;
  background: #121a2e;
}

#banner {
  background-size: cover;
  width: 100%;
}
.jumbotron {
  width: 100%;
}

/********** banner **********/
#banner header {
  overflow: hidden;
}

/********** feature (skew background) **********/
.feature img {
  width: 100%;
  max-width: 480px;
}

#feature-first {
  background: linear-gradient(168deg, #ffffff 55%, #00ffad 0);
  width: 100%;
}
#feature-first p {
  color: black;
}

#feature-last {
  background: #00ffad;
  width: 100%;
}
#feature-last p{
  color: black;
}

/********** price table **********/
#price-table {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

#price-table__annual {
  background: #192440;
  color: #ffffff;
}
#price-table__monthly p {
  color: black;
}
#price-table__semester p {
  color: black;
}
#price-table ul li {
  padding: 5px 0;
}

/********** contact **********/
#contact {
  background-size: cover;
}

p {
  font-size: 16px;
}

#contact ul li {
  padding: 3px 0;
}

#contact form {
  color: #00ffad;
}

#contact form input,
#contact form textarea {
  background: transparent;
  border: 1px solid #00ffad;
  color: #ffffff;
}

/********** copyright **********/
#copyright {
  background: #192440;
  padding: 2rem 3rem;
}

#copyright #social-media a {
  width: 40px;
  height: 40px;
  border-radius: 99%;
  background: #ffffff;
  transition: 0.4s ease;
}

#copyright #social-media a i {
  color: #192440;
  font-size: 1.2rem;
  line-height: 40px;
}

#copyright #social-media a:hover {
  background: #00ffad;
}

#copyright #social-media a:hover i {
  color: #ffffff;
}

/********** RWD **********/
@media (max-width: 575px) {
  #banner header img {
    max-width: 80px;
  }
  /* .small-logo {
    display: block;
  } */
  /* .complete-logo {
    display: none;
  } */
  #banner h1 {
    font-size: 40px !important;
  }
}

@media (min-width: 575px) {
  #banner h1 {
    font-size: 72px !important;
  }
  #banner header img {
    max-width: 300px;
  }
  .small-logo {
    display: none;
  }
  /* .complete-logo {
    display: block;
  } */
}

@media (max-width: 992px) {
  .center-button {
    display: flex;
    justify-content: center;
  }
}
</style>
